import React from 'react';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import './Footer.css';

function Copyright() {
  return (
    <div>
      <h2 className="nav__title">
        {'Copyright © '}
        <Link color="inherit">TPO, IITJ</Link> {new Date().getFullYear()}
        {'.'}
      </h2>
    </div>
  );
}

const footers = [
  {
    title: 'Downloads',
    description: [
      ['JAF', '/jaf'],
      ['IAF', '/iaf'],
      ['Brochure', '/brochure'],
    ],
  },
  {
    title: 'Quick Links',
    description: [
      ['Placement registration link', '/recruiter-login'],
      ['Placement Statistics', '/placement-statistics'],
      ['Meet our Team', '/placement-team'],
    ],
  },
  {
    title: 'External Links',
    description: [
      ['IIT Jodhpur', 'https://iitj.ac.in/'],
      ['Student Gymkhana', 'https://students.iitj.ac.in/'],
    ],
  },
];

export default function Footer(props) {
  return (
    <>
      <footer className="footer">
        <div className="footer__addr">
          <h1 className="">TPO IITJ</h1>

          {/* <h2>Contact</h2> */}

          <address>
            Indian Institute of Technology Jodhpur<br></br>
            NH 62 Nagaur Road<br></br>
            Karwar 342030, Jodhpur District
            <br />
            <b>Phone:</b> +91 291 2801155<br></br>
            <b>Phone:</b> +91 291 2801154<br></br>
            <div>
              {' '}
              <b>Handles: </b>
              <Tooltip arrow title="facebook">
                <IconButton
                  href="https://www.facebook.com/SPC.IITJ/"
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="outlined"
                  style={{ color: '#111111' }}
                >
                  <FacebookIcon style={{ color: '#111111' }} />
                </IconButton>
              </Tooltip>
              <Tooltip arrow title="Linkedin">
                <IconButton
                  href="https://in.linkedin.com/in/career-development-cell-iit-jodhpur-62a31352"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#111111' }}
                  variant="outlined"
                >
                  <LinkedInIcon style={{ color: '#111111' }} />
                </IconButton>
              </Tooltip>
              <Tooltip arrow title="GitHub">
                <IconButton
                  href="https://github.com/devlup-labs/cdc-portal"
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="outlined"
                  style={{ color: '#111111' }}
                >
                  <GitHubIcon />
                </IconButton>
              </Tooltip>
            </div>
            <a className="footer__btn" href="mailto:placement@iitj.ac.in">
              <b>Email:</b> &nbsp;placement@iitj.ac.in
            </a>
          </address>
        </div>

        <ul className="footer__nav">
          {footers.map((footer) => (
            <li className="nav__item nav__item--extra" key={footer.title}>
              <h2 className="nav__title">{footer.title}</h2>
              <ul className="nav__ul">
                {footer.description.map((item) => (
                  <li key={item}>
                    <Link
                      href={item[1]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item[0]}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </footer>
      <div
        style={{
          height: '1px',
          background: '#111',
          margin: '',
          width: '100%',
        }}
      ></div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem 0px 1rem 0',
          textAlign: 'center',
          background: '#232323',
          color: '#F7F7F7',
        }}
      >
        <h2 className="nav__title">
          Designed & Maintained by Office of Training and Placement, IIT Jodhpur
        </h2>
        <Copyright className="nav__title" />
      </div>
    </>
  );
}

import React from 'react';

export default function BrochureCard() {
  return (
    <div
      style={{
        height: '160px',
        background: 'white',
        width: '300px',
        borderRadius: '1rem',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxShadow: '0px 16px 32px 0px rgba(120, 120, 0, 0.08)',
      }}
    >
      <div style={{ alignSelf: 'flex-start' }}>
        <h2 style={{ fontWeight: '600' }}>Brochure</h2>
        <p style={{ fontWeight: '400' }}>Placement & Interships</p>
        <p style={{ fontWeight: '200' }}>2024-25</p>
      </div>

      <a
        href="/brochure"
        style={{
          alignSelf: 'center',
          color: '#9E6531',
          textDecoration: 'none',
          fontSize: '20px',
        }}
      >
        Open
      </a>
    </div>
  );
}

import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './LazyImage.css';

export default function LazyImage({ src, alt, width, height, ...props }) {
  return (
    <LazyLoadImage
      src={src}
      width={width}
      height={height}
      alt={alt}
      placeholder={<div className="image_placeholder"></div>}
      effect="blur"
      {...props}
    />
  );
}

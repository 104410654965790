import React from 'react';
import './BadgeContainer.css';

export default function BadgeContainer({ children }) {
  return (
    <div className="badge-container">
      <div className="ribbon"></div>
      <div className="content">{children}</div>
    </div>
  );
}

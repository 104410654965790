import React from 'react';
import Container from '@material-ui/core/Container';
import styles from './AboutUs.module.css';

const AboutUs = ({ data }) => {
  const createAboutUs = () => {
    return { __html: data.content };
  };

  return (
    <React.Fragment>
      <div style={{ marginTop: '6em' }}>
        <div
          style={{
            fontSize: 11,
            textAlign: '-webkit-center',
            justifyContent: 'center',
            display: 'flex',
            marginBottom: -10,
            marginTop: 70,
          }}
        >
          <h2 className={styles.aboutUsTitle}>{data.title}</h2>
        </div>
        <h3>
          <hr style={{ width: '60%', margin: '10px auto' }} />
        </h3>
      </div>
      {data ? (
        <Container
          className={`${styles.textContainer} ${styles.textContainerHover}`}
        >
          <p dangerouslySetInnerHTML={createAboutUs()} />
        </Container>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default AboutUs;

import React from 'react';
import Slider from 'react-slick';
import BusinessIcon from '@material-ui/icons/Business';
import Container from '@material-ui/core/Container';
import styles from './PastRecruiters.module.css';
import { getLink } from '../../utils/getLink';
import LazyImage from '../LazyImage/LazyImage';

const PastRecruiters = ({ data }) => {
  const getPR_Objs = () => {
    let list = [];

    data.map((PR) => {
      return list.push(
        <div key={PR.id} className={styles.PR_image}>
          {/* <CardMedia
              className={styles.PR_image}
              image={getLink(PR.company_logo)}
            /> */}
          {/* <img src={getLink(PR.company_logo)} alt={PR.id}></img> */}
          <LazyImage
            src={getLink(PR.company_logo)}
            width={110}
            height={200}
            alt={PR.id}
          />
        </div>
      );
    });

    return list;
  };
  var settings = {
    dots: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div className="py-5">
        <Container style={{ maxWidth: '100%', marginTop: '6em' }}>
          <div>
            <h2
              style={{
                fontSize: 31,
                textAlign: '-webkit-center',
                justifyContent: 'center',
                display: 'flow-root',
                marginBottom: -10,
                marginTop: 70,
                color: 'rgb(29, 22, 66)',
              }}
            >
              <BusinessIcon
                style={{
                  margin: '0 0.8rem -0.4rem 0',
                  fontSize: '2.5rem',
                  paddingTop: '0rem',
                }}
              />
              Past Recruiters
            </h2>
            <h3>
              <hr style={{ width: '60%', margin: '25px auto 30px auto' }} />
            </h3>
          </div>
          <Slider className={styles.slider} {...settings}>
            {getPR_Objs()}
          </Slider>

          {/* Grid Layout */}
          {/* <div className={styles.gridContainer}>
            {data.map((PR) => (
              <div key={PR.id} className={styles.gridItem}>
                <CardMedia
                  className={styles.PR_image}
                  image={getLink(PR.company_logo)}
                />
              </div>
            ))}
          </div> */}
        </Container>
      </div>
    </div>
  );
};

export default PastRecruiters;

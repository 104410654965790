import React from 'react';
import styles from '../styles/components/C3MemberCard.module.css';
import Container from '@material-ui/core/Container';
import Director from '../assets/Director.webp';
// import Dean from '../assets/sjghosh.webp';
// import AssociateDean from '../assets/ankur_gupta.webp';

const DirectorDean = () => {
  return (
    <Container style={{ maxWidth: '80%', marginTop: '6em' }}>
      {/* <div className={styles.teamContainer}>
        <div className={styles.teamCard}>
          <div className={styles.imageContainer}>
            <img className={styles.profileImage} src={Dean} alt="Dean" />
          </div>
          <div className={styles.cardContent}>
            <b style={{ margin: '0', fontSize: '1.6rem', textAlign: 'center' }}>
              Prof. Surajit Ghosh
            </b>
            <br></br>
            <div className={styles.department}>
              Dean ( International, Alumni & Corporate Relations )
            </div>
          </div>
        </div> */}

      <div className={styles.teamCard}>
        <div className={styles.imageContainer}>
          <img className={styles.profileImage} src={Director} alt="Director" />
        </div>
        <div className={styles.cardContent}>
          <b style={{ margin: '0', fontSize: '1.6rem', textAlign: 'center' }}>
            Prof. Avinash Kumar Agarwal
          </b>
          <br></br>
          <div className={styles.department}>Director</div>
        </div>
      </div>

      {/* <div className={styles.teamCard}>
          <div className={styles.imageContainer}>
            <img
              className={styles.profileImage}
              src={AssociateDean}
              alt="AssociateDean"
            />
          </div> */}
      {/* <div className={styles.cardContent}>
            <b style={{ margin: '0', fontSize: '1.6rem', textAlign: 'center' }}>
              Dr. Ankur Gupta
            </b>
            <br></br>
            <div className={styles.department}>
              Associate Dean ( International Relations & Outreach )
            </div>
          </div> */}
      {/* </div> */}
      {/* </div> */}
    </Container>
  );
};

export default DirectorDean;

import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

export default function AcademicDetailsForm({ p1, p2, isEditMode }) {
  const handleChangegpa = (event) => {
    p2({ ...p1, ...{ gpa: event.target.value } });
  };
  const handleChangeugclg = (event) => {
    p2({ ...p1, ...{ ugclg: event.target.value } });
  };
  const handleChangeugpa = (event) => {
    p2({ ...p1, ...{ ugpa: event.target.value } });
  };
  const handleChangeugyear = (event) => {
    p2({ ...p1, ...{ ugyear: event.target.value } });
  };
  const handleChangeugprbr = (event) => {
    p2({ ...p1, ...{ ugprbr: event.target.value } });
  };
  const handleChangejamscore = (event) => {
    p2({ ...p1, ...{ jamscore: event.target.value } });
  };
  const handleChangegatescore = (event) => {
    p2({ ...p1, ...{ gatescore: event.target.value } });
  };
  const handleChangebacklog = (event) => {
    p2({ ...p1, ...{ backlog: event.target.value } });
  };
  const handleChangejeeairmain = (event) => {
    p2({ ...p1, ...{ jeeairmain: event.target.value } });
  };
  const handleChangejeeairadv = (event) => {
    p2({ ...p1, ...{ jeeairadv: event.target.value } });
  };
  const handleChangeby12 = (event) => {
    p2({ ...p1, ...{ by12: event.target.value } });
  };
  const handleChangeby10 = (event) => {
    p2({ ...p1, ...{ by10: event.target.value } });
  };
  const handleChangebn12 = (event) => {
    p2({ ...p1, ...{ bn12: event.target.value } });
  };
  const handleChangebn10 = (event) => {
    p2({ ...p1, ...{ bn10: event.target.value } });
  };
  const handleChangebp12 = (event) => {
    p2({ ...p1, ...{ bp12: event.target.value } });
  };
  const handleChangebp10 = (event) => {
    p2({ ...p1, ...{ bp10: event.target.value } });
  };
  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            required
            name="CGPA"
            label="CGPA"
            type="number"
            fullWidth
            autoComplete="CGPA"
            value={p1.gpa}
            onChange={handleChangegpa}
            helperText="Please fill in your current CGPA here(all students). For first year PG students who don't have a CGPA yet, fill in the CGPA of your previous qualification(Undergraduate)"
            disabled={isEditMode}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="UG College"
            label="UG College"
            type="text"
            fullWidth
            autoComplete="UG College"
            helperText="This field is only for P.G. students"
            value={p1.ugclg}
            onChange={handleChangeugclg}
          />
          <TextField
            name="UG CGPA"
            label="UG CGPA"
            type="number"
            fullWidth
            autoComplete="UG CGPA"
            helperText="This field is only for P.G. students"
            value={p1.ugpa}
            onChange={handleChangeugpa}
          />
          <TextField
            name="UG Passing Year"
            label="UG Passing Year"
            type="number"
            fullWidth
            autoComplete="UG Passing Year"
            helperText="This field is only for P.G. students"
            value={p1.ugyear}
            onChange={handleChangeugyear}
          />
          <TextField
            name="UG Program And Branch"
            label="UG Program And Branch"
            type="text"
            fullWidth
            autoComplete="UG Program And Branch"
            helperText="This field is only for P.G. students"
            value={p1.ugprbr}
            onChange={handleChangeugprbr}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="Gate Score"
            label="Gate Score"
            type="number"
            fullWidth
            autoComplete="Gate Score"
            helperText="This field is only for P.G. students"
            value={p1.gatescore}
            onChange={handleChangegatescore}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="JAM Score"
            label="JAM Score"
            type="number"
            fullWidth
            autoComplete="JAM Score"
            helperText="This field is only for P.G. students"
            value={p1.jamscore}
            onChange={handleChangejamscore}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="JEE AIR(Main)"
            label="JEE AIR(Main)"
            type="number"
            fullWidth
            autoComplete="JEE AIR(Main)"
            value={p1.jeeairmain}
            required
            onChange={handleChangejeeairmain}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="JEE AIR(Advanced)"
            label="JEE AIR(Advanced)"
            type="number"
            fullWidth
            autoComplete="JEE AIR(Advanced)"
            value={p1.jeeairadv}
            required
            onChange={handleChangejeeairadv}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="12th Board Year"
            label="12th Board Year"
            type="number"
            fullWidth
            autoComplete="12th Board Year"
            error={p1.by12.length > 4}
            helperText={
              p1.by12.length > 4
                ? "Length of 12th Board Year can't be more than 4"
                : ''
            }
            value={p1.by12}
            onChange={handleChangeby12}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="10th Board Year"
            label="10th Board Year"
            type="number"
            fullWidth
            autoComplete="10th Board Year"
            error={p1.by10.length > 4}
            helperText={
              p1.by10.length > 4
                ? "Length of 10th Board Year can't be more than 4"
                : ''
            }
            value={p1.by10}
            onChange={handleChangeby10}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="12th Board Name"
            label="12th Board Name"
            fullWidth
            autoComplete="12th Board Name"
            error={p1.bn12.length > 100}
            helperText={
              p1.bn12.length > 100
                ? "Length of 12th Board Name can't be more than 100"
                : ''
            }
            value={p1.bn12}
            onChange={handleChangebn12}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="10th Board Name"
            label="10th Board Name"
            fullWidth
            autoComplete="10th Board Name"
            error={p1.bn10.length > 100}
            helperText={
              p1.bn10.length > 100
                ? "Length of 10th Board Name can't be more than 100"
                : ''
            }
            value={p1.bn10}
            onChange={handleChangebn10}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="12th Board %"
            label="12th Board %"
            type="number"
            fullWidth
            autoComplete="12th Board %"
            error={p1.bp12.length > 10}
            helperText={
              p1.bp12.length > 10
                ? "Length of 12th Board %/CGPA can't be more than 10"
                : ''
            }
            value={p1.bp12}
            onChange={handleChangebp12}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="10th Board %/CGPA"
            label="10th Board %/CGPA"
            type="number"
            fullWidth
            autoComplete="10th Board %/CGPA"
            error={p1.bp10.length > 10}
            helperText={
              p1.bp10.length > 10
                ? "Length of 10th Board %/CGPA can't be more than 10"
                : ''
            }
            value={p1.bp10}
            onChange={handleChangebp10}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel required>Any Backlogs</InputLabel>
            <Select
              fullWidth
              required
              value={p1.backlog}
              onChange={handleChangebacklog}
              disabled={isEditMode}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

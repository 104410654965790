import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import pdfFile from '../../assets/Placement Policy _ 2024-25.pdf';

export default function NormsForm({ p1, p2 }) {
  const handleChange = (event) => {
    p2({ check: event.target.checked });
  };
  return (
    <React.Fragment>
      <div>
        <iframe
          src={pdfFile}
          title="PDF Viewer"
          width="100%"
          height="500px"
        ></iframe>
      </div>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="confirm"
              checked={p1}
              onChange={handleChange}
            />
          }
          label=" I hereby agree to all the norms of the Office of Training and Placement and promise to abide by the same."
        />
      </Grid>
    </React.Fragment>
  );
}

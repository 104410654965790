import React from 'react';
import PlacementProcedureComponent from '../components/PlacementProcedure/PlacementProcedure';
const PlacementProcedure = () => {
  return (
    <div>
      <PlacementProcedureComponent />
    </div>
  );
};

export default PlacementProcedure;
